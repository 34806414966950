import { useNavigate } from 'react-router-dom';
import {
    useTypedParams,
    useTypedSearchParams,
} from 'react-router-typesafe-routes/dom';

import { Button, Stack, Typography } from '@mui/material';

import { useEffectOnce } from '../../hooks/use-effect-once';
import { useSkattUpload } from '../../queries/use-skatt-upload';
import { useStore } from '../../store/store';
import { SendingFlow } from '../../utils/types';
import { ROUTES } from '../routes-map';
import { useDocumentTypeSelect } from './components/document-type-select/document-type-select';
import { useFileSelect } from './use-file-select';
import { useDocumentUploadForm } from './use-upload-document-form';
import { useUploadedFiles } from './use-uploaded-files';

export const FinanceDataUploadRoute = () => {
    const navigate = useNavigate();
    const { processId } = useTypedParams(ROUTES.FINANCE_DATA_UPLOAD);

    const [{ submissionMode, requestKey, refererRoute }] = useTypedSearchParams(
        ROUTES.FINANCE_DATA_UPLOAD,
    );

    const { addUploadedFile, uploadedFiles, isSkattemeldingUploaded } =
        useUploadedFiles();

    const uploadDocumentForm = useDocumentUploadForm();
    const { DocumentTypeSelect, documentType } = useDocumentTypeSelect();

    const skattUpload = useSkattUpload();

    const { FileSelect, selectedFileName, selectedFile } = useFileSelect({
        form: uploadDocumentForm,
    });

    const setSendingFlow = useStore((state) => state.setSendingFlow);
    const setDestinationKey = useStore((state) => state.setDestinationKey);
    const setRefererRoute = useStore((state) => state.setRefererRoute);

    useEffectOnce(() => {
        if (submissionMode) {
            setSendingFlow(submissionMode as SendingFlow);
        }

        if (requestKey) {
            setDestinationKey(requestKey);
        }

        if (refererRoute) {
            setRefererRoute(refererRoute);
        }
    });

    const onSubmit = uploadDocumentForm.handleSubmit(async (values) => {
        try {
            const formData = new FormData();
            formData.append('document', selectedFile[0]);
            formData.append(
                'documentDescription',
                JSON.stringify({
                    processId,
                    documentType,
                }),
            );

            await skattUpload.mutateAsync({
                data: formData as any,
            });

            addUploadedFile(selectedFile[0], documentType);

            uploadDocumentForm.reset();
        } catch (error) {
            console.error(error);
        }
    });

    return (
        <div>
            <h2>Last opp skattedokumenter</h2>
            <p>
                Vi forklarer deg hvordan du steg for steg kan finne og laste opp
                dokumenter fra skatteetaten. Du laster de ned på PC-en og laster
                de opp på tjenesten her.
            </p>

            <p>
                Det er mulig å gjøre dette på mobil eller nettbrett, men det er
                lettest å gjøre det på datamaskin. Vil du at vi skal sende en
                epost til deg så du kan fortsette på datamaskin litt senere?
            </p>

            <h3>Steg-for-steg-forklaring:</h3>
            <div>
                <ol>
                    <li>
                        Gå til:{' '}
                        <a
                            href="https://skatt.skatteetaten.no/web/mineskatteforhold/skattedokumenter"
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: 'inherit' }}
                        >
                            skatteetaten
                        </a>{' '}
                        og logg inn med Bank-ID.
                    </li>
                    <li>
                        Scroll ned til du finner "Skattemelding 2023 som
                        dokumentasjon". Det finnes mange ulike versjoner å velge
                        mellom, men det er kun "Skattemelding 2023 som
                        dokumentasjon" som er riktig. Åpne pdf-dokumentet. Trykk
                        "Print" og velg "lagre som pdf".
                    </li>
                    <li>
                        Det neste vi skal laste ned er lønnsdokumentasjon. Trykk
                        pil tilbake, og deretter på skatteetat-logoen. Du kommer
                        da til startsiden. Velg “Min side”, og deretter "Min
                        side" på nytt. Velg "Min skatt". Deretter scroller du
                        ned til "Inntekter og arbeidsforhold", trykk på haken
                        ved siden av og gå inn på "Mine inntekter og
                        arbeidsforhold". For å få lagret dette som pdf må du
                        gjøre følgende: Trykk ctrl + p på tastaturet, og
                        deretter "lagre som pdf".
                    </li>
                    <li>
                        Det siste er opplysninger om deg fra folkeregisteret.
                        Trykk “tilbakeknappen” in browseren 2 ganger slik at du
                        slik at du kommer tilbake til forsiden igjen. Der velger
                        du "Folkeregisteret". På neste side så trykker du "Jeg
                        vil se mine opplysninger" i menyen på vestre side. Klikk
                        på haken på alle 3 linjene slik at all informasjon
                        kommer frem (“Personopplysninger”, “Adresser og
                        "Familie") Trykk ctrl + p på tastaturet, og print den
                        som PDF.
                    </li>
                    <li>
                        Trykk last opp og finn dokumentene i dine nedlastede
                        filer.
                    </li>
                    <li>Send inn dokumentet ditt til oss</li>
                </ol>
            </div>
            <p>
                <a
                    href="https://player.vimeo.com/video/873634792"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#10307b' }}
                >
                    Se steg for steg video
                </a>
            </p>
            <form
                onSubmit={onSubmit}
                noValidate
                style={{
                    backgroundColor: 'transparent',
                    padding: '20px 0 0',
                }}
            >
                <Stack direction="row" spacing={2} alignItems="center">
                    <FileSelect />
                    <DocumentTypeSelect disabled={!selectedFileName} />
                    <div>
                        <Button
                            variant="contained"
                            type="submit"
                            style={{ minWidth: 200 }}
                            disabled={
                                !selectedFileName || skattUpload.isLoading
                            }
                        >
                            LAST OPP DOKUMENT
                        </Button>
                    </div>
                </Stack>

                {selectedFileName && (
                    <div>
                        <Typography variant="body1" style={{ marginTop: 20 }}>
                            Du har valgt å laste opp filen{' '}
                            <strong>{selectedFileName}</strong>
                        </Typography>
                    </div>
                )}

                <div
                    style={{
                        backgroundColor: '#f5f5f5',
                        color: '#10307b',
                        padding: 20,
                        margin: '20px 0',
                    }}
                >
                    {uploadedFiles.length > 0 && (
                        <Stack
                            direction="column"
                            spacing={2}
                            alignItems="flex-start"
                            style={{
                                width: '100%',
                            }}
                        >
                            <h5>Filer lastet opp av deg</h5>
                            {uploadedFiles.map((item, index) => (
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    style={{ width: '100%' }}
                                    key={index}
                                >
                                    <div>{item.name}</div>
                                    <div>{item.type}</div>
                                </Stack>
                            ))}
                        </Stack>
                    )}
                    {uploadedFiles.length === 0 && (
                        <h5>
                            Du har ikke lastet opp noen filer enda. Last opp
                            filer for å fortsette
                        </h5>
                    )}
                </div>
            </form>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '0 0 40px 0',
                }}
            >
                <Button
                    variant="contained"
                    disabled={!isSkattemeldingUploaded}
                    onClick={() =>
                        navigate(
                            ROUTES.FINANCE_DATA_SUBMIT.buildPath({
                                processId,
                            }),
                        )
                    }
                >
                    SEND
                </Button>
            </div>
        </div>
    );
};
