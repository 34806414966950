import { Box } from '../../components/box';
import { Button } from '../../components/button';
import { Container } from '../../components/container';
import { ErrorBox } from '../../components/error-box';
import { Faq } from '../../components/faq';
import { useStore } from '../../store/store';
import { useForwardDocuments } from './hooks/use-forward-documents';

export function SubmitCRMRoute() {
    const { isError, isLoading } = useForwardDocuments();

    const refererRoute = useStore((state) => state.refererRoute);

    if (isLoading) {
        return (
            <Box variant="primary">
                <h1>Autorisasjon</h1>
                Vi sjekker om vi kan få dine data fra offentlige registre. Det
                tar vanligvis 5-10 sekunder. Vennligst vent.
                <p>...</p>
            </Box>
        );
    }

    if (isError) {
        return (
            <ErrorBox>
                <a href="https://www.eiendomsfinans.no/">
                    <Button variant="primary">Avslutt</Button>
                </a>
            </ErrorBox>
        );
    }

    if (refererRoute) {
        return (
            <Container>
                <h1 style={{ marginBottom: 20 }}>
                    Din søknad er oversendt Eiendomsfinans
                </h1>
                <h3>
                    En rådgiver fra Eiendomsfinans vil ta kontakt med deg
                    snarest, for å finne tilbud og løsninger som er tilpasset
                    dine ønsker og behov.
                </h3>
                <Faq
                    items={[
                        {
                            question: `Gratis tjeneste`,
                            answer: `Jobben Eiendomsfinans gjør er alltid kostnadsfri og uforpliktende for deg som kunde. Dersom de finner et nytt lån for deg som du velger å benytte, får de betalt av banken for jobben som er gjort.`,
                        },
                        {
                            question: `En løsning til ditt beste`,
                            answer: `Det jobbes etter etiske prinsipper avtalt mellom Inkassoregisteret og Eiendomsfinans, herunder at løsningene skal være til ditt beste, og at det skal være enkelt og tydelig forklart for deg før du gjør noen valg.`,
                        },
                    ]}
                />
                <p>
                    For å kunne hjelpe deg trenger rådgiveren i Eiendomsfinans
                    mer dokumentasjon. Du kan starte å samle den inn allerede
                    nå. Mye av det kan hentes automatisk ved hjelp av BankID.
                </p>
                <div>
                    <a href={refererRoute}>
                        <Button variant="secondary">Fullfør søknad →</Button>
                    </a>
                </div>
            </Container>
        );
    }

    return (
        <Box variant="primary">
            <h1>Autorisasjonen var vellykket</h1>
            <p>
                Dine data blir sendt din rådgiver, som vil ta kontakt med deg!
            </p>
            <a href="https://www.eiendomsfinans.no/">
                <Button variant="primary">Avslutt</Button>
            </a>
        </Box>
    );
}
